import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {Track} from "../../../../entities/Track.entity";
import {TooltipDirective} from "ngx-bootstrap/tooltip";
import {PlayerService} from "../../../player/player.service";
import {TRACK_TYPE} from "../../../../enums/TRACK_TYPE";
import {TRACK_COLLECTION_TEMPO} from "../../../../enums/TrackCollection/TRACK_COLLECTION_TEMPO";


@Component({
  selector: 'app-track-item',
  templateUrl: './track-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackItemComponent implements OnInit {
  @Input('track') public track!: Track;
  @Input('deleteEnabled') public deleteEnabled: boolean = false;
  @Input('dragNDropEnabled') public dragNDropEnabled: boolean = false;
  @Output('deleted') public deleted: EventEmitter<Track> = new EventEmitter<Track>();

  public selectedTrack!: Track;

  public trackType = TRACK_TYPE;
  public shareLink!: string;

  public tempi = TRACK_COLLECTION_TEMPO;

  public ngOnInit(): void {
    this.selectedTrack = this.track;
    this.shareLink = location.origin + '/tracks/' + this.track.track_collection_id;
    if(this.track.type == TRACK_TYPE.SFX) {
      this.shareLink = location.origin + '/sfx/' + this.track.track_collection_id;
    }
    this.cdr.markForCheck();
  }

  public playTrack() {
    this.playerService.$selectedTrack.next({tc: this.track.track_collection!, t: this.selectedTrack});
    this.cdr.markForCheck();
  }

  public triggerDelete() {
    if(this.deleteEnabled) {
      this.deleted.next(this.track);
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private playerService: PlayerService
  ) {}
}

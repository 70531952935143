import {IEnvironment} from "./IEnvironment";

export const environment: IEnvironment = {

  production: true,
  env_name: 'stage',
  frontend: 'https://dayfox-stage.teraone-preview.de',
  api: {
    url: 'https://api.dayfox-stage.teraone-preview.de/api',
    session_domain: '.dayfox-stage.teraone-preview.de'
  },
  default_artist_user_id: '97a9fb28-efd4-4691-ac84-81729618e49d',
  special_playlist_id: '9950408c-2646-40f5-a959-7dc318ef7c30'
};

import { Pipe, PipeTransform } from '@angular/core';
import { differenceInDays } from 'date-fns'

@Pipe({
  name: 'remainingDays'
})
export class RemainingDaysPipe implements PipeTransform {
  public transform(value: any): number {
    if(typeof value === 'string' && value !== '' && new Date(value)) {
      const laterDate = new Date(value);
      const now = new Date();
      return differenceInDays(laterDate, now);
    }
    return NaN;
  }
}

import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {USER_ROLE} from "../../../enums/USER_ROLE";
import {UserSessionService} from "../../services/user-session.service";


@Injectable({
  providedIn: 'root',
})
export class IsLoggedInGuard  {
  public canActivate(): Observable<boolean> {
    return this.userSessionService.loggedIn$.asObservable().pipe(
      filter(l => typeof l === 'boolean'),
      take(1),
      switchMap(() => this.userSessionService.user$),
      map(user => {
        if (user && user.role === USER_ROLE.ADMIN) {
          return true;
        }
        this.router.navigateByUrl('/login');
        return false;
      }),
    );
  }

  public constructor(private userSessionService: UserSessionService, private router: Router, private log: NGXLogger) {}
}

<div class="custom-tags-input">
  <span class="label badge badge-primary" *ngFor="let tag of tags; let i = index">
    <span class="text">
      {{ tag.displayValue }}
    </span>
    <button *ngIf="config.tagsDeletable" (click)="removeTag(i)" (touchend)="removeTag(i)" class="remove-btn">
      <span aria-hidden="true">&times;</span>
    </button>
  </span>
  <input
    *ngIf="!config.withTypeahead; else withTypeahead"
    class="input-field"
    type="text"
    [placeholder]="config.inputPlaceholder ?? ''"
    name="tags"
    (keyup.enter)="config.directTagAdding ? addTag(tagInput) : addTagExternal(tagInput)"
    (keydown.backspace)="removeLastTag(tagInput)"
    (input)="onInputFieldChange($event)"
    [disabled]="!config.tagsAddable || maxTagsReached"
    [hidden]="!config.tagsAddable || maxTagsReached"
    #tagInput />
  <ng-template #withTypeahead>
    <input
      *ngIf="typeaheadConfig !== null && config.withTypeahead"
      class="input-field"
      type="text"
      [placeholder]="config.inputPlaceholder ?? ''"
      name="tags"
      (keydown.backspace)="removeLastTag(tagInput)"
      (keyup.enter)="addTagExternal(tagInput)"
      [(ngModel)]="selectedTypeaheadOption"
      (input)="onInputFieldChange($event)"
      [typeahead]="typeaheadOptions"
      [typeaheadOptionField]="'displayValue'"
      [typeaheadAsync]="true"
      [typeaheadWaitMs]="300"
      (typeaheadOnSelect)="typeaheadOnSelect($event)"
      (typeaheadNoResults)="typeaheadOnNoMatch($event)"
      [typeaheadMinLength]="typeaheadConfig.minLengthBeforeOptions"
      [typeaheadScrollable]="typeaheadConfig.scrollableOptions"
      [typeaheadIsFirstItemActive]="false"
      [typeaheadLatinize]="true"
      [typeaheadOptionsInScrollableView]="typeaheadConfig.scrollableOptionsInView"
      [disabled]="!config.tagsAddable || maxTagsReached"
      [hidden]="!config.tagsAddable || maxTagsReached"
      #tagInput />
  </ng-template>
</div>

import {DefaultUrlSerializer, UrlSerializer, UrlTree} from "@angular/router";
import queryString from "query-string";

export class CustomUrlSerializer implements UrlSerializer{
  // A custom url serializer is needed since angular automatically transforms single item array query params into strings,
  // but we want to keep them as arrays
  public parse(url: string): UrlTree {
    let dus = new DefaultUrlSerializer();
    let parse = dus.parse(url);
    let parsedQuery = queryString.parse(url.split('?')[1], {
      decode: true,
      arrayFormat: "bracket"
    });
    parse.queryParams = parsedQuery;
    return parse;
  }
  public serialize(tree: UrlTree): string {
    let dus = new DefaultUrlSerializer();
    let path = dus.serialize(tree);
    let stringParsedQuery = queryString.stringify(tree.queryParams, {
      encode: true,
      arrayFormat: "bracket"
    });
    // Might need a different way to overwrite queryParams but works for now
    return path.split('?')[0] + (stringParsedQuery ? ('?' + stringParsedQuery) : '');
  }
}

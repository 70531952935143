import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ColumnMode, SelectionType} from '@swimlane/ngx-datatable';
import {ToastrService} from 'ngx-toastr';
import {ColumnData} from '../../../../entities/ColumnData.entity';
import {PaginatedResponse} from '../../../../entities/BaseEntity.entity';
import {TableSortEntity} from '../../../../entities/TableSort.entity';
import {ColumnVisalizationType} from '../../../../enums/COLUMN_VISUALIZATION_TYPE';
import {DatatableEventEntity} from "../../../../entities/DatatableEvent.entity";
import {PlayerService} from 'src/app/modules/player/player.service';
import {AdminTrackCollectionsService} from "../../../services/api/methods/admin/admin-track-collection.service";
import {take} from "rxjs";

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html'
})
export class TableComponent<T> implements OnChanges {
  @Input() public paginatedResponse?: PaginatedResponse<T>;
  @Input() public exportEndpoint?: string;
  @Input() public favoriteKey?: string = '';
  @Input() public columnsSelectable = true;
  @Input() public exportModificationFunction?: (input: any) => any;
  @Input() public selectedRows: T[] = [];
  @Input() public loading = false;
  @Input() public sorts: TableSortEntity[] = [];
  @Input() public paginationHandling: 'table' | 'url' = 'table';
  @Output() public readonly pageChanged = new EventEmitter<{ page: number}>();
  @Output() public readonly sortSearchChanged = new EventEmitter<{ page: number; search?: string; sorts?: TableSortEntity[] }>();
  @Output() public readonly loadContent = new EventEmitter<{ page: number; search?: string; sorts?: TableSortEntity[] }>();
  @Output() public readonly selectedRowsChange = new EventEmitter<T[]>();
  @Output() public readonly rowClick = new EventEmitter<DatatableEventEntity<T>>();
  @Output() public readonly updateRow = new EventEmitter<{key: string, row: T}>();
  @Output() public readonly customActions = new EventEmitter<any>();
  public editingCell: { id?: string; value?: string } = {};
  public filteredColumns: ColumnData[] = this.allColumns;
  public ColumnMode = ColumnMode;
  public ColumnVisalizationType = ColumnVisalizationType;
  public search: string = '';
  public pageOffset: number = 1;
  public showBadge?: boolean = false;
  public selectionType = SelectionType.checkbox;
  public cssClasses = {
    sortAscending: 'fa-solid fa-caret-down ps-1',
    sortDescending: 'fa-solid fa-caret-up ps-1',
    pagerLeftArrow: 'fa-solid fa-caret-left',
    pagerRightArrow: 'fa-solid fa-caret-right',
    pagerPrevious: 'fa-solid fa-backward-fast',
    pagerNext: 'fa-solid fa-forward-fast'
  };

  private _allColumns: ColumnData[] = [];

  public get allColumns(): ColumnData[] {
    return this._allColumns;
  }

  @Input() public set allColumns(value: ColumnData[]) {
    this._allColumns = value;

    this.filteredColumns = value;
  }

  public onSelect( selected: { selected: T[] } ): void {
    if (!this.editingCell.id) {
      this.selectedRows.splice(0, this.selectedRows.length);
      this.selectedRows.push(...selected.selected);
      this.selectedRowsChange.emit(selected.selected);
    }
  }

  public onActivate(event: DatatableEventEntity<T>): void {
    const col = this.allColumns.find(column => column.name === event?.column?.prop);
    if (
      event.type === 'click' &&
      this.selectedRows.length === 0 &&
      event.cellIndex !== 0 &&
      !(col?.type === ColumnVisalizationType.LINK || col?.type === ColumnVisalizationType.SELECT || col?.type === ColumnVisalizationType.PLAY_BUTTON || col?.type === ColumnVisalizationType.ACTIONS || col?.type === ColumnVisalizationType.CHECKBOX) &&
      !this.editingCell.id
    ) {
      this.rowClick.emit(event);
    }
  }

  public onPage(event: any) {
    this.pageOffset = event.offset + 1;
    if(this.paginationHandling === 'url') {
      this.pageChanged.emit({page: event.offset + 1});
    } else {
      this.loadContent.emit({ page: event.offset + 1, search: this.search, sorts: this.sorts })
    }
  }

  public onSort(event: { sorts: any[]; }): void {
    this.sorts = [event.sorts[0]];
    this.sortSearchChanged.emit({ page: 1, search: this.search, sorts: this.sorts });
  }

  public onSearch(event: string): void {
    this.search = event;
    this.sortSearchChanged.emit({ page: 1, search: this.search, sorts: this.sorts });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['loading']) {
      this.cdr.markForCheck();
    }
  }

  public editCell(event: Event, cellName: string, rowIndex: number, row: T): void {
    event.stopPropagation();
    this.editingCell.id = rowIndex + '-' + cellName;
    // @ts-ignore
    this.editingCell.value = row[cellName];
  }

  public saveRow(event: Event, row: T, key: string): void {
    event.stopPropagation();
    // @ts-ignore
    row[key] = this.editingCell.value;
    this.updateRow.emit({key: key, row: row});
    this.editingCell = {};
  }

  public playTrack(row: T): void {
    // @ts-ignore
    if(row['id'] && (row['primary_track'] || row['tracks'])) {
      // @ts-ignore
      this.adminTrackCollectionService.get(row['id']).pipe(take(1)).subscribe({
        next: res => {
          if(res.data.primary_track || (res.data.tracks && res.data.tracks.length > 0)) {
            this.playerService.playTrack(res.data, (res.data.primary_track ?? res.data.tracks[0]));
          }
        }
      });
    }
  }

  public updateValue(value: any, columnName: string | number, rowIndex: any, row: T | undefined): void {
    // @ts-ignore
    row[columnName] = value;
    this.updateRow.emit({key: columnName + '', row: row!});
  }

  public triggerAction(row: T, actionName: string) {
    this.customActions.emit({
      [actionName]: row
    });
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private playerService: PlayerService,
    private adminTrackCollectionService: AdminTrackCollectionsService
  ) {}
}

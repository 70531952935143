<div [class.d-none]="!visible" class="main-player">
  <div class="row h-100 align-items-center px-3">

    <div class="col-12 col-lg-4">
      <div class="player-control-track-info-container text-center text-lg-start">
        <div [class.d-none]="trackLoading"  class="play-control">
          <div (click)="stop()" class="backwards-control pointer">
            <i class="fas fa-step-backward"></i>
          </div>
          <div (click)="updatePlayStatus()" class="play-stop-control pointer">
            <div class="h-100 w-100 d-flex">
              <i *ngIf="!playing" class="fas fa-play fa-2x"></i>
              <i *ngIf="playing" class="fas fa-stop fa-2x"></i>
            </div>
          </div>
          <div class="repeat-control">
              <i class="fas fa-redo"></i>
          </div>
        </div>

        <div [class.d-none]="!trackLoading"  class="d-inline-block align-middle mx-5">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div *ngIf="selectedTrackCollection" class="track-info">
          <span class="align-middle">
            <b>{{ selectedTrack?.title ?? selectedTrackCollection.title }}</b><br>
            <span>by {{ selectedTrackCollection.artist?.name ? selectedTrackCollection.artist.name : selectedTrackCollection.source_artist_name }}</span>
          </span>
        </div>
      </div>

    </div>

    <div class="col-12 col-lg-6">
      <div [class.d-none]="!waveformLoading" class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>

      <div [class.d-none]="waveformLoading" class="wavesurfer-container">
        <div id="mainWavesurfer"></div>
      </div>

      <div class="timer-volume-container">
        <div class="float-start">
          <span>{{ trackCurrentTime * 1000 | date: 'm:ss' }} / {{ trackDuration * 1000 | date: 'm:ss' }} </span>
        </div>

        <div class="volume-range float-end">
          <i class="fas fa-volume-up pe-3 align-middle"></i>
          <div class="volume-control">
            <input type="range"
                   class="form-range volume-range-input"
                   min="0"
                   max="1"
                   step="0.01"
                   [formControl]="volumeFormControl"
            >
          </div>
        </div>
      </div>
    </div>

  </div>

  <div (click)="close()" class="player-close pointer">
    <i class="fas fa-times"></i>
  </div>
</div>

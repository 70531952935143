import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output
} from '@angular/core';
import WaveSurfer from 'wavesurfer.js';
import {NGXLogger} from "ngx-logger";
import {Track} from "../../entities/Track.entity";
import {TrackCollection} from "../../entities/TrackCollection.entity";
import {HttpClient} from "@angular/common/http";
import {Waveform} from "../../entities/Waveform.entity";
import {PlayerService} from "./player.service";
import {debounceTime, take} from "rxjs/operators";
import {UntypedFormControl} from "@angular/forms";
import {WebStorageService} from "ngx-webstorage-service";
import {PlayerSettings} from "../../entities/PlayerSettings.entity";
import {StorageService} from "../services/storage.service";
import {ToastrService} from "ngx-toastr";
import {AdminTrackFileUrlService} from "../services/api/methods/admin/admin-track-file-url.service";
import {switchMap} from "rxjs";

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerComponent implements AfterViewInit, OnDestroy {
  public initalized: boolean = false;
  public selectedTrackCollection?: TrackCollection;
  public selectedTrack?: Track;
  public selectedTrackWaveform?: Waveform;
  public wavesurferPlayer!: WaveSurfer;
  public volumeFormControl: UntypedFormControl = new UntypedFormControl(0.9);
  public playing: boolean = false;
  public trackDuration: number = 0;
  public trackCurrentTime: number = 0;
  public waveformLoading: boolean = false;
  public trackLoading: boolean = false;
  public visible: boolean = this.playerService.$displayPlayer.getValue();

  private playerSettingsStorageKey = 'player_settings';
  private playerSettings?: PlayerSettings;

  ngOnInit(): void {
    this.playerSettings = this.getPlayerSettings();

    this.playerService.$displayPlayer.subscribe({
      next: value => {
        this.visible = value;
        this.cdr.markForCheck();
      }
    });

    this.playerService.$selectedTrack.subscribe({
      next: value => {
        if(value) {
          this.playerSetup(value.t, value.tc);
        }
      }
    });

    this.initVolumeHandler();
  }

  ngAfterViewInit(): void {
    if(this.initalized) {
      this.wavesurferPlayer = new WaveSurfer({
        container: '#mainWavesurfer',
        backend: 'MediaElement',
        height: 25,
        responsive: true,
        fillParent: true,
        normalize: true,
        barMinHeight: 0.5,
        progressColor: '#FF742C',
        cursorColor: '#00000000'
      });

      this.wavesurferPlayer.init();
      this.wavesurferPlayer.setVolume(this.playerSettings?.volume ?? this.volumeFormControl.value);
      this.preparePlayer();

      this.wavesurferPlayer.on('ready', () => {
        this.trackDuration = this.wavesurferPlayer.getDuration();
        this.trackCurrentTime = this.wavesurferPlayer.getCurrentTime();
        this.trackLoading = false;
        this.wavesurferPlayer.play();
        this.playing = this.wavesurferPlayer.isPlaying();
        this.cdr.detectChanges();
      });

      this.wavesurferPlayer.on('audioprocess', () => {
        this.updateCurrentTime();
      });

      this.wavesurferPlayer.on('interaction', () => {
        this.updateCurrentTime();
      });

      this.wavesurferPlayer.on('seek', () => {
        this.updateCurrentTime();
      });

      this.wavesurferPlayer.on('finish', () => {
        this.playing = this.wavesurferPlayer.isPlaying();
        this.cdr.detectChanges();
      });
    }
    this.cdr.markForCheck();
  }

  private setTrack(waveform: Waveform | null) {
    this.trackFileUrlService.mp3(this.selectedTrack!.id).subscribe({
      next: res => {
        if(waveform?.data) {
          this.selectedTrackWaveform = waveform;
          this.wavesurferPlayer.load(res.url, waveform.data);
        } else {
          this.wavesurferPlayer.load(res.url, []);
        }
      },
      error: err => {
        this.toastr.error('Track has no mp3 file');
        this.log.error('Unable to retrieve mp3 file', {track: this.selectedTrack, trackCollection: this.selectedTrackCollection});
      }
    });
  }

  ngOnDestroy(): void {
    this.initalized = false;
    this.wavesurferPlayer.unAll();
    // this.wavesurferPlayer.destroy();
    this.selectedTrackWaveform = undefined;
    this.selectedTrack = undefined;
    this.selectedTrackCollection = undefined;
    this.cdr.detectChanges();
  }

  public stop() {
    this.wavesurferPlayer.stop();
    this.playing = false;
    this.cdr.detectChanges();
  }

  public updatePlayStatus() {
    this.wavesurferPlayer.playPause();
    this.playing = this.wavesurferPlayer.isPlaying();
    this.cdr.markForCheck();
  }

  public close() {
    this.stop();
    this.playerService.$displayPlayer.next(false);
  }

  private preparePlayer() {
    this.wavesurferPlayer.empty();
    this.playing = this.wavesurferPlayer.isPlaying();
    this.trackDuration = this.wavesurferPlayer.getDuration();
    this.trackCurrentTime = this.wavesurferPlayer.getCurrentTime();
  }

  private playerSetup(track: Track, trackCollection: TrackCollection) {
    this.preparePlayer();
    this.trackLoading = true;
    this.waveformLoading = true;
    this.selectedTrackCollection = trackCollection;
    this.selectedTrack = track;
    this.trackFileUrlService.waveform(track.id).pipe(
      take(1),
      switchMap(
        res => {
          return this.http.get<Waveform>(res.url);
        }
      )
    ).subscribe({
      next: value => {
        this.setTrack(value);
        this.waveformLoading = false;
        this.cdr.detectChanges();
      },
      error: err => {
        this.toastr.error('Track has no waveform');
        this.log.error('Unable to retrieve waveform file', err);
      }
    });
  }

  private updateCurrentTime() {
    this.trackCurrentTime = this.wavesurferPlayer.getCurrentTime();
    this.cdr.detectChanges();
  }

  private initVolumeHandler() {
    this.volumeFormControl.setValue(this.playerSettings?.volume ?? 0.9);
    this.volumeFormControl.valueChanges.pipe(
      debounceTime(400)
    ).subscribe({
      next: (value: number) => {
        this.wavesurferPlayer.setVolume(value);
        console.log(this.wavesurferPlayer.getVolume());
        this.setPlayerSettings({volume: value});
        this.cdr.markForCheck();
      }
    });
  }

  private getPlayerSettings(): PlayerSettings {
    return this.storage.get<PlayerSettings>(this.playerSettingsStorageKey);
  }

  private setPlayerSettings(settings: Partial<PlayerSettings>) {
    let playerSettings =  this.storage.get<PlayerSettings>(this.playerSettingsStorageKey);
    playerSettings = {...playerSettings, ...settings};
    this.playerSettings = playerSettings;
    this.storage.set(this.playerSettingsStorageKey, playerSettings);
  }

  public constructor(
    private cdr: ChangeDetectorRef,
    private log: NGXLogger,
    private http: HttpClient,
    private playerService: PlayerService,
    private trackFileUrlService: AdminTrackFileUrlService,
    private storage: StorageService,
    private toastr: ToastrService
  ) {
    this.initalized = true;
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {Track} from "../../../entities/Track.entity";
import {COMBINED_TRACK_FILE_STATES} from "../../../enums/Import/COMBINED_TRACK_FILE_STATES";
import {getCombinedTrackFileState} from "../../../utils/GetCombinedTrackFileState";

@Pipe({
  name: 'getCombinedTrackFileState'
})
export class GetCombinedTrackFileStatePipe implements PipeTransform {
  public transform(value: Track): COMBINED_TRACK_FILE_STATES {
    return getCombinedTrackFileState(value);
  }
}

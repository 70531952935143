<ng-container *ngIf="paginationMeta">
  <pagination
    [boundaryLinks]="true"
    [maxSize]="paginationMeta.last_page"
    [itemsPerPage]="paginationMeta.per_page"
    [totalItems]="paginationMeta.total"
    (pageChanged)="changed($event)"
    [customFirstTemplate]="firstTemplate"
    [customLastTemplate]="lastTemplate"
    [customPreviousTemplate]="prevTemplate"
    [customNextTemplate]="nextTemplate"
  ></pagination>

  <ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class="fas fa-arrow-right" [class.text-muted]="disabled"></i>
  </ng-template>

  <ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class="fas fa-arrow-left" [class.text-muted]="disabled"></i>
  </ng-template>

  <ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class="fas fa-step-forward" [class.text-muted]="disabled"></i>
  </ng-template>

  <ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class="fas fa-step-backward" [class.text-muted]="disabled"></i>
  </ng-template>
</ng-container>

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { StorageService as NGXWebstorage } from 'ngx-webstorage-service';

export const MY_STORAGE_SERVICE = new InjectionToken<StorageService>('MY_STORAGE_SERVICE');

@Injectable()
export class StorageService {
  public get<T>(key: string): T {
    return this.storage.get(key);
  }

  public set(key: string, value: unknown): void {
    this.storage.set(key, value);
  }

  public delete(key: string): void {
    this.storage.remove(key);
  }
  public has(key: string): boolean {
    return this.storage.has(key);
  }

  public constructor(@Inject(MY_STORAGE_SERVICE) private storage: NGXWebstorage) {}
}

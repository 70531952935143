import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../../../environments/environment';

@Injectable()
export abstract class ApiService {
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      if (error.status !== 404 && error.status !== 401) {
        this.error(`${operation} failed: ${error.message}`);
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  protected error(message: string): void {
    if (!environment.production) {
      this.log.error('Api Service Error:' + message);
    }
  }
  protected constructor(protected http: HttpClient, public log: NGXLogger) {}
}

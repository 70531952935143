import {
  AfterContentChecked, AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {NGXLogger} from "ngx-logger";
import {ToastrService} from "ngx-toastr";
import {Category} from "../../../../entities/Category.entity";
import {TagService} from "../../../services/api/methods/category/tag.service";
import {CATEGORY_TYPES} from "../../../../enums/CATEGORY_TYPES";
import {AdminCategoryService} from "../../../services/api/methods/admin/admin-category.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Observable, of} from "rxjs";
import {PaginatedResponse} from "../../../../entities/BaseEntity.entity";

@Component({
  selector: 'app-tags-input',
  templateUrl: './tag-input.component.html',
})
export class TagInput implements OnInit, OnChanges, AfterViewChecked {
  @Input('modelTags') public modelTags: Category[] = [];
  @Input('hasError') public hasError: boolean = false;
  @Input('maxTags') public maxTags: number = 70;
  @Input('tagOptions') public tagOptions: Observable<{ displayValue: string, model: Category, id: string }[]> = of();
  @Input('optionsLoading') public optionsLoading: boolean = false;


  @Output('modelTagsChanged') public modelTagsChanged: EventEmitter<Category[]> = new EventEmitter<Category[]>()
  @Output() public inputChanged: EventEmitter<string> = new EventEmitter<string>()

  public trackCollectionTags: { 'displayValue': string, 'model': Category, 'id': string }[] = [];
  public tagNotFound: boolean = true;
  public tempAddTag: Partial<Category> | undefined;
  public confirmCreateTagModalRef?: BsModalRef;
  public tagsInput?: any;

  public tagsInputDebounceTimer: any;

  @ViewChild('confirmCreateTagModal') public confirmCreateTagModal!: TemplateRef<any>;

  public customTagInputConfig = {
    directTagAdding: false,
    withTypeahead: true,
    tagsDeletable: true,
    tagsAddable: true,
    removeLastTagOnBackspace: true,
    maxTags: this.maxTags
  };

  public customTagInputTypeaheadConfig = {
    scrollableOptions: false,
    minLengthBeforeOptions: 1,
    scrollableOptionsInView: 2
  };

  public ngOnInit(): void {
    this.trackCollectionTags = this.modelTags.map((value => {
      return {displayValue: value.title, model: value, 'id': value.id};
    }));
    this.cdr.markForCheck();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes['modelTags']) {
      this.trackCollectionTags = this.modelTags.map((value => {
        return {displayValue: value.title, model: value, 'id': value.id};
      }));
    }
    this.cdr.markForCheck();
  }

  public onTagsChanged(event: any): void {
    const modelTags = this.trackCollectionTags.map((value) => {
      return value.model;
    })
    this.cdr.detectChanges();
    this.modelTagsChanged.emit(modelTags);
    this.cdr.markForCheck();
  }

  public onNoOptionsMatch(event: any): void {
    this.tagNotFound = event;
  }

  public addTagTrigger(tag: string): void {
    // if (this.tagNotFound) {
      this.tempAddTag = {
        type: CATEGORY_TYPES.tag,
        parent_id: null,
        title: tag,
        key: (tag.toLowerCase()).split(" ").join('_')
      };
      this.confirmCreateTagModalRef = this.modalService.show(this.confirmCreateTagModal);
    // }
  }

  public createTag() {
    this.confirmCreateTagModalRef?.hide();
    if (this.tempAddTag) {
      this.adminCategoryService.create(this.tempAddTag).subscribe({
        next: res => {
          // this.tagOptions.push({displayValue: res.data.title, model: res.data, id: res.data.id});
          this.trackCollectionTags.push({displayValue: res.data.title, model: res.data, id: res.data.id});
          this.onTagsChanged(null);
          this.cdr.markForCheck();
        },
        error: err => {
          this.log.error(err);
          this.toastr.error('Error' + JSON.stringify(err.error));
          this.cdr.markForCheck();
        }
      });
    }
  }

  public onInputChanged(input: string) {
      this.inputChanged.emit(input);
  }

  public constructor(
    private cdr: ChangeDetectorRef,
    private log: NGXLogger,
    private toastr: ToastrService,
    private tagService: TagService,
    private adminCategoryService: AdminCategoryService,
    private modalService: BsModalService
  ) {
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { CollapseModule } from "ngx-bootstrap/collapse";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ToastNoAnimationModule, ToastrModule } from "ngx-toastr";
import { UserSelectorComponent } from "./components/user-selector/user-selector.component";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {TableComponent} from "./components/table/table.component";
import {SharedPipesModule} from "./pipes/shared-pipes.module";
import { DragDropModule } from "@angular/cdk/drag-drop";
// import {NgxTagsInputModule} from "ngx-tags-input";
import {TagInput} from "./components/tag-input/tag-input.component";
import {FormImageUploadComponent} from "./components/form-image-upload/form-image-upload.component";
import {ArtistSelectorComponent} from "./components/artist-selector/artist-selector.component";
import {PlaylistSelectorComponent} from "./components/playlist-selector/playlist-selector.component";
import {TrackItemComponent} from "./components/track-item/track-item.component";
import {FormVideoUploadComponent} from "./components/form-video-upload/form-video-upload.component";
import {AccordionModule} from "ngx-bootstrap/accordion";
import {TabsModule} from "ngx-bootstrap/tabs";
import {NgxWebstorageModule} from "ngx-webstorage";
import {CookieService} from "ngx-cookie-service";
import {CustomTagInputComponent} from "./components/custom-tag-input/custom-tag-input.component";
import {GetNestedObjectValuePipe} from "./pipes/get-nested-object.pipe";
import {RemainingDaysPipe} from "./pipes/remaining-days.pipe";
import {ListPaginatorComponent} from "./components/list-paginator/list-paginator.component";

const SHARED_MODULES = [
  CommonModule,
  RouterModule,
  ModalModule.forRoot(), /// this has serious problems with SSR angular/universal
  TooltipModule,
  BsDropdownModule.forRoot(),
  BsDatepickerModule.forRoot(),
  TimepickerModule.forRoot(),
  TypeaheadModule.forRoot(),
  FormsModule,
  ReactiveFormsModule,
  CollapseModule,
  PaginationModule.forRoot(),
  NgxDatatableModule,
  ToastNoAnimationModule.forRoot({
    // positionClass: 'toast-bottom-full-width',
    countDuplicates: true,
    onActivateTick: true,
    maxOpened: 100,
    preventDuplicates: true,
    resetTimeoutOnDuplicate: true,
    timeOut: 10000,
    extendedTimeOut: 20000,
    tapToDismiss: true,
    closeButton: true,
  }),
  AutocompleteLibModule,
  SharedPipesModule,
  DragDropModule,
  // NgxTagsInputModule,
  AccordionModule.forRoot(),
  TabsModule.forRoot(),
  NgxWebstorageModule.forRoot(),
];

const SHARED_COMPONENTS = [
  FormGroupComponent,
  UserSelectorComponent,
  TableComponent,
  TagInput,
  FormImageUploadComponent,
  ArtistSelectorComponent,
  PlaylistSelectorComponent,
  TrackItemComponent,
  FormVideoUploadComponent,
  CustomTagInputComponent,
  ListPaginatorComponent,
  GetNestedObjectValuePipe,
  RemainingDaysPipe
];

const SHARED_PROVIDERS = [
  CookieService
];


@NgModule({
  declarations: [SHARED_COMPONENTS],
  imports: [SHARED_MODULES],
  exports: [SHARED_MODULES, SHARED_COMPONENTS],
  providers: [SHARED_PROVIDERS],
})
export class SharedModule {}

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { catchError, map, switchMap, takeWhile } from 'rxjs/operators';
import {BehaviorSubject, interval, Observable, of, take, tap} from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {LocalStorageService} from "ngx-webstorage";
import {User} from "../../entities/User.entity";
import {SingleResponse} from "../../entities/BaseEntity.entity";
import {AuthenticationService} from "./api/methods/authentication.service";

type StoredUser = Pick<User, 'id' | 'email' | 'name' | 'role'>;


@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  public loggedIn$: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(undefined);
  public user$: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);

  private STORAGE_USER_NAME = 'user';

  private _redirectAfterLogin: string | null = null;

  private STORAGE_USER_IMAGE_NAME = 'user-image';

  // -------------
  // Login Related
  // -------------
  public loginUser(email: string, password: string): Observable<SingleResponse<User>> {
    return this.authenticationService.login(email, password).pipe(
      take(1),
      tap({
        next: res => {
          this.handleLogin(res.data)
          this.storageService.store('login', new Date())
        }
      })
    )
  }

  public handleLogin(user: User): void {
    this.storage.set(this.STORAGE_USER_NAME, {
      id: user.id,
      email: user.email,
      name: user.name,
      role: user.role
    })
    this.user$.next(user)
    this.loggedIn$.next(true);
  }

  public redirectAfterLogin(): boolean {
    if (this._redirectAfterLogin) {
      this.router.navigateByUrl(this._redirectAfterLogin).then(
        () => {
          this._redirectAfterLogin = null;
          this.log.log('redirected');
        },
        () => {
          this.log.error('could not redirect');
        },
      );
      return true;
    }

    return false;
  }

  public setRedirectAfterLogin(redirectTo: string | null): void {
    this._redirectAfterLogin = redirectTo;
  }

  // -----------------
  // User Info Related
  // -----------------
  public getUserId(): Observable<string | undefined> {
    return of(this.storage.get<StoredUser>(this.STORAGE_USER_NAME)).pipe(
      map(user => {
        if (user && user.id) {
          return user.id
        } else {
          return undefined
        }
      }),
      catchError(() => of(undefined))
    )
  }

  public getStoredUserInfo(): Observable<StoredUser | undefined> {
    return of(this.storage.get<StoredUser>(this.STORAGE_USER_NAME)).pipe(
      map(user => {
        if (user) {
          return user
        } else {
          return undefined
        }
      })
    )
  }

  private isLoggedIn(): Observable<User | null> {
    return this.authenticationService.check().pipe(
      take(1),
      map(res => {
        this.handleLogin(res.data)
        return res.data
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.handleLogout()
            return of(null)
          }
        }
        throw err
      })
    )
  }

  // --------------
  // Logout Related
  // --------------
  public logout(): Observable<void> {
    return this.authenticationService.logout().pipe(
      take(1),
      tap({
        next: () => {
          this.storageService.store('logout', true)
          this.handleLogout()
        },
        error: () => {
          // Why would we hande a logout that didn't happen ??? (copied code part from other project)
          // this.handleLogout()
          this.log.warn('[auth] could not logout user')
        }
      })
    );
  }

  private handleLogout(): void {
    this.clearUserCache();
    this.user$.next(undefined);
    this.loggedIn$.next(false);
  }

  // -------------------
  // Update User Related
  // -------------------

  public handleUserUpdate(user: User): void {
    this.storage.set(this.STORAGE_USER_NAME, {
      id: user.id,
      email: user.email,
      name: user.name,
      role: user.role
    })
    this.user$.next(user)
  }

  // --------------------------
  // User Check/Refresh Related
  // --------------------------
  public refreshUser(): Observable<SingleResponse<User>> {
    return this.authenticationService.check().pipe(
      take(1),
      tap({
        next: res => {
          this.handleLogin(res.data)
        }
      })
    );
  }

  // --------------------------
  // Misc
  // --------------------------
  private clearUserCache(): void {
    this.storage.delete(this.STORAGE_USER_NAME);
    this.storageService.clear(this.STORAGE_USER_IMAGE_NAME);
  }

  public constructor(
    private router: Router,
    private storage: StorageService,
    private log: NGXLogger,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private storageService: LocalStorageService,
  ) {
    this.isLoggedIn().subscribe({
      next: value => {
        if (value === null || value === undefined) {
          this.clearUserCache()
        } else {
          this.user$.next(value)
        }
      }
    });

    this.storageService.observe('logout').subscribe(
      () => {
        this.handleLogout()
        this.storageService.clear('logout')
      }
    );

    this.storageService.observe('login').pipe(
      switchMap(d => this.isLoggedIn())
    ).subscribe(
      (u) => {
        if (u) {
          this.handleLogin(u);
        }
      }
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LOCAL_STORAGE } from 'ngx-webstorage-service';
import { MY_STORAGE_SERVICE, StorageService } from './storage.service';
import {AuthenticationService} from "./api/methods/authentication.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [StorageService, { provide: MY_STORAGE_SERVICE, useExisting: LOCAL_STORAGE }],
})
export class ServicesModule {}

export enum ColumnVisalizationType {
  DATE = 'date',
  BADGE = 'badge',
  LINK = 'link',
  ICON_ONLY = 'iconOnly',
  FILE = 'file',
  SELECT = 'select',
  UUID = 'uuid',
  LIST = 'list',
  STATUS_LIGHT = 'statusLight',
  ICON_TEXT = 'iconText',
  FIRST_ITEM = 'firstItem',
  TEXT_ONLY = 'textOnly',
  ICON_NOT_NULL = 'iconNotNull',
  PLAY_BUTTON = 'playButton',
  ACTIONS = 'actions',
  IMAGE = 'image',
  CHECKBOX = 'checkbox',
  REMAINING_DAYS = 'remaining_days'
}

<ngx-datatable
  (activate)="onActivate($event)"
  (page)="onPage($event)"
  (select)="onSelect($event)"
  (sort)="onSort($event)"
  [columnMode]="ColumnMode.force"
  [count]="paginatedResponse?.meta?.total || 0"
  [externalPaging]="true"
  [externalSorting]="true"
  [footerHeight]="50"
  [headerHeight]="50"
  [limit]=" paginatedResponse?.meta?.per_page || 40"
  [loadingIndicator]="loading"
  [offset]="paginatedResponse && paginatedResponse.meta ? paginatedResponse.meta.current_page - 1 : 0"
  [rowHeight]="'auto'"
  [rows]="paginatedResponse?.data || []"
  [scrollbarH]="true"
  [selected]="selectedRows"
  [selectionType]="selectionType"
  [virtualization]="false"
  [cssClasses]="cssClasses"
  [sorts]="sorts"
  class="bootstrap expandable"
>
  <ngx-datatable-column
                        [canAutoResize]="false"
                        [checkboxable]="true"
                        [draggable]="false"
                        [headerCheckboxable]="true"
                        [resizeable]="false"
                        [sortable]="false"
                        [width]="30"
  >
  </ngx-datatable-column>

  <ng-container *ngFor="let column of filteredColumns">
    <ngx-datatable-column
      name="{{ column.label }}"
      prop="{{ column.name }}"
      [width]="column.width ?? 200"
      [sortable]="column.sortable !== undefined ? column.sortable : true"
    >
      <ng-template let-index="index" let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <ng-container *ngFor="let item of (value | isArray) ? value : [value]; index as valueIndex; let last = last">

          <ng-container [ngSwitch]="column.type">
            <ng-container *ngSwitchCase="ColumnVisalizationType.DATE">
              {{ item | date: 'dd-MM-y' }}
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.LINK">
              <a *ngIf="item" href="{{ item }}" target="_blank"><i class="fa fa-external-link-alt"></i></a>
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.FIRST_ITEM">
              <span *ngIf="valueIndex === 0">{{ (column.itemKey ? item[column.itemKey] : item) }}</span>
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.LIST">
              <span>{{ (column.itemKey ? item[column.itemKey] : item) }}</span><br *ngIf="!last" />
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.UUID">
              {{ item | truncateString: { characters: 6 } }}
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.FILE">
              <a class="d-block" *ngIf="item" href="{{ (row.attachments | arrayFindByUuid: item)?.signed_url }}" target="_blank">
                {{ (row.attachments | arrayFindByUuid: item)?.filename }}
                <i class="fa fa-external-link-alt"></i
                ></a>
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.STATUS_LIGHT">
              <i class="fas fa-circle text-success" [class.text-danger]="row[column.name.split('-')[0]]?.set.indexOf(column.itemKey) == -1"></i>
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.ICON_TEXT">
<!--              <mat-icon *ngIf="(column.icons | getmap: row[column.name])">{{ column.icons | getmap: row[column.name] }}</mat-icon>-->
<!--              <span *ngIf="!(column.icons | getmap: item)"></span>-->
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.ICON_ONLY">
              <i class="fas {{ column.icons | getmap: row[column.name] }}" *ngIf="(column.icons | getmap: row[column.name])"></i>
<!--              <i class="fas {{ column.defaultIcon }}" *ngIf="!(column.icons | getmap: row[column.name])"></i>-->
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.ICON_NOT_NULL">
              <i class="fas {{ column.defaultIcon }}" *ngIf="row[column.name] !== null"></i>
              <!--              <i class="fas {{ column.defaultIcon }}" *ngIf="!(column.icons | getmap: row[column.name])"></i>-->
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.TEXT_ONLY">
              <span *ngIf="(column.icons | getmap: (row | getNestedObjectValue: column.name))">{{ column.icons | getmap: (row | getNestedObjectValue: column.name) }}</span>
<!--              <span *ngIf="!(column.icons | getmap: row[column.name])">{{ column.defaultIcon }}</span>-->
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.REMAINING_DAYS">
              <span>{{ (item | remainingDays) !== (item | remainingDays) ?  '' : (item | remainingDays) }}</span>
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.SELECT">
              <div class="form-group">
                <select [ngModel]="value" (ngModelChange)="updateValue($event, column.name, rowIndex, row)" class="form-control w-auto">
                  <option *ngFor="let select of column.selectable_values" [value]="select.value">{{ select.name }}</option>
                </select>
              </div>
<!--              <mat-form-field>-->
<!--                <mat-label>{{ column.label }}</mat-label>-->
<!--                <mat-select-->
<!--                  (ngModelChange)="updateValue($event, column.name, rowIndex, row)"-->
<!--                  [multiple]="!!column.select_multiple"-->
<!--                  [ngModel]="value"-->
<!--                >-->
<!--                  <mat-option disabled="">Select</mat-option>-->
<!--                  <mat-option *ngFor="let select of column.selectable_values" [value]="select.value">{{ select.name }}</mat-option>-->
<!--                </mat-select>-->
<!--              </mat-form-field>-->
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.PLAY_BUTTON">
              <button type="button" class="btn-clear play-button play-button-list" (click)="playTrack(row)">
                <i class="fa fa-play"></i>
              </button>
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.ACTIONS">
              <button *ngFor="let action of column.actions" type="button" class="btn btn-clear" (click)="triggerAction(row, action.name)">
                <i class="{{ action.iconClasses }}"></i>
              </button>
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.IMAGE">
              <img *ngIf="column.img_src" class="table-preview-image" src="{{row[column.img_src.main] ?? row[column.img_src.alt]}}" alt="{{ column.name }}">
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.CHECKBOX">
              <input [ngModel]="value" (ngModelChange)="updateValue($event, column.name, rowIndex, row)" class="form-check-input" type="checkbox" [value]="row[column.name]">
            </ng-container>

            <ng-container *ngSwitchDefault>
              <i *ngIf="column.icons && item" class="fas {{ column.icons | getmap: item }}"></i>
              <div *ngIf="column.type !== ColumnVisalizationType.ICON_ONLY"
                   class="d-flex justify-content-between editable-cell align-items-center">
                <span
                  *ngIf="editingCell.id !== rowIndex + '-' + column.name"
                  style="display: -webkit-inline-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden;"
                >
                  {{ item }}
                </span>

                <button
                  (click)="editCell($event, column.name, rowIndex, row)"
                  *ngIf="column.editable && editingCell.id !== rowIndex + '-' + column.name"
                  class="btn btn-outline-secondary"
                >
                  <i class="fas fa-edit"></i>
                </button>

                <button
                  (click)="saveRow($event, row, column.name)"
                  *ngIf="editingCell.id === rowIndex + '-' + column.name"
                  class="btn btn-outline-primary"
                >
                  Save
                </button>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </ngx-datatable-column>
  </ng-container>
</ngx-datatable>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getNestedObjectValue'
})
export class GetNestedObjectValuePipe implements PipeTransform {
  public transform(value: any, accessor: string): any {
    let accessorList: string[] = [];
    if(accessor.includes('.')) {
      accessorList = accessor.split('.');
    } else {
      accessorList.push(accessor);
    }

    if(typeof value !== "object" || value === null || value === undefined) {
      return value
    }

    for(const prop of accessorList) {
      if(value && value.hasOwnProperty(prop)) {
        value = value[prop];
      } else {
        return undefined;
      }
    }

    return value;
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {debounceTime, filter, startWith, switchMap, take} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Artist } from "../../../../entities/Artist.entity";
import {ArtistService} from "../../../services/api/methods/artist/artist.service";
import {AdminArtistService} from "../../../services/api/methods/admin/admin-artist.service";
import {ARTIST_STATUS} from "../../../../enums/ARTIST_STATUS";

@Component({
  selector: 'app-artist-selector',
  templateUrl: './artist-selector.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ArtistSelectorComponent,
    },
  ],
})
export class ArtistSelectorComponent implements ControlValueAccessor, OnInit {
  @Input('formControl') public originalFormControl!: UntypedFormControl;
  @Input('label') public inputLabel: string = 'Artist';

  public autocompleteFormControl = new UntypedFormControl('', { updateOn: 'change' });
  public autocompleteSearchKeyword = 'name';
  public autocompleteSearchData: any[] = [];

  public artist: Artist | null = null;

  public options: Artist[] = [];
  public optionsVisible: boolean = false;

  public displayFn(artist: Artist): string {
    return artist && artist.name ? artist.name + (artist.user ? '(@' + artist.user.email + ')' : '') : '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onChange = (id: string | null): void => {
    //empty
  };

  public onTouched = (): void => {
    //empty
  };

  public onSelected(event: any) {
    this.artist = event;
    this.originalFormControl.setValue(this.artist?.id ?? null);
    this.cdr.markForCheck();
  }

  public clear(): void {
    this.markAsTouched();
    if (!this.autocompleteFormControl.disabled) {
      this.artist = null;
      this.originalFormControl.setValue(null);
      this.autocompleteFormControl.setValue(null);
      this.onChange(null);
    }
    this.cdr.markForCheck();
  }

  public writeValue(artistId: string | null): void {
    if (artistId) {
      this.http.get('/admin/artists/' + artistId).pipe(take(1)).subscribe(
        data => {
          // @ts-ignore
          this.artist = data.data as Artist;
          this.cdr.markForCheck();
        }
      );
    } else {
      this.artist = null;
    }
    this.cdr.markForCheck();
  }

  public registerOnChange(onChange: () => unknown): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => unknown): void {
    this.onTouched = onTouched;
  }

  public markAsTouched(): void {
    if (!this.autocompleteFormControl.touched) {
      this.onTouched();
      this.autocompleteFormControl.markAsTouched();
    }
  }

  public setDisabledState(disabled: boolean): void {
    disabled ? this.autocompleteFormControl.disable() : this.autocompleteFormControl.enable();
  }

  public ngOnInit() {
    this.autocompleteFormControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        filter(v => {
          const isString = typeof v === 'string';
          if (!isString && v && v.id) {
            this.markAsTouched();
            // if (!this.autocompleteFormControl.disabled) {
            //   this.artist = v.data;
            //   this.onChange(v.data.id);
            // }
          }
          return isString;
        }),
        switchMap((search: string) => {
          return this.adminArtistService.searchAll(1, {q: search});
        }),
      )
      .subscribe(p => {
        this.autocompleteSearchData = p.data;
        this.cdr.markForCheck();
      });
  }

  public constructor(private http: HttpClient, private log: NGXLogger, private cdr: ChangeDetectorRef, private adminArtistService: AdminArtistService) {}
}

import {
  Component, EventEmitter, Input, Output
} from '@angular/core';
import {PaginationMeta} from "../../../../entities/BaseEntity.entity";
import {PageChangedEvent} from "ngx-bootstrap/pagination";

@Component({
  selector: 'app-list-paginator',
  templateUrl: './list-paginator.component.html',
})
export class ListPaginatorComponent {
  @Input() public paginationMeta?: PaginationMeta;
  @Output() public pageChanged: EventEmitter<PageChangedEvent> = new EventEmitter<PageChangedEvent>()

  public changed(event: PageChangedEvent): void {
    this.pageChanged.next(event);
  }

  constructor() {}
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../../api.service';
import {PaginatedResponse, SingleResponse} from "../../../../../entities/BaseEntity.entity";
import {TrackCollection} from "../../../../../entities/TrackCollection.entity";
import {TRACK_COLLECTION_TYPE} from "../../../../../enums/TRACK_COLLECTION_TYPE";
import {TRACK_COLLECTION_STATUS} from 'src/app/enums/TRACK_COLLECTION_STATUS';
import {SearchBody} from "../../../../../entities/SearchBody.entitiy";
import queryString from "query-string";

@Injectable({
  providedIn: 'root'
})
export class AdminTrackCollectionsService extends ApiService {
  private endpoint = '/admin/track-collections';

  public getAll(page: number = 1): Observable<PaginatedResponse<TrackCollection>> {
    return this.http.get<PaginatedResponse<TrackCollection>>(this.endpoint + '?page=' + page);
  }

  public getAllNew(page: number = 1): Observable<PaginatedResponse<TrackCollection>> {
    return this.http.get<PaginatedResponse<TrackCollection>>(this.endpoint + '/status/new?page=' + page);
  }

  public getAllNewSFX(page: number = 1): Observable<PaginatedResponse<TrackCollection>> {
    return this.http.get<PaginatedResponse<TrackCollection>>(this.endpoint + '/status/new/type/sfx?page=' + page);
  }

  // public create(data: Partial<Artist>): Observable<SingleResponse<Artist>> {
  //   return this.http.post<SingleResponse<Artist>>(this.endpoint, data);
  // }

  public searchAll(page: number = 1, searchBody: SearchBody): Observable<PaginatedResponse<TrackCollection>> {
    const searchQuery = queryString.stringify({page: page,...searchBody}, {
      arrayFormat: "bracket"
    });
    return this.http.get<PaginatedResponse<TrackCollection>>('/admin/search/track-collections' + '?' + searchQuery);
  }

  public searchAllSFX(page: number = 1, searchBody: SearchBody): Observable<PaginatedResponse<TrackCollection>> {
    const searchQuery = queryString.stringify({page: page,...searchBody}, {
      arrayFormat: "bracket"
    });
    return this.http.get<PaginatedResponse<TrackCollection>>('/admin/search/track-collections/type/sfx' + '?' + searchQuery);
  }

  public get(id: string): Observable<SingleResponse<TrackCollection>> {
    return this.http.get<SingleResponse<TrackCollection>>(this.endpoint + '/' + id);
  }

  public update(id: string, data: Partial<TrackCollection>): Observable<SingleResponse<TrackCollection>> {
    return this.http.put<SingleResponse<TrackCollection>>(this.endpoint  + '/' + id, data);
  }

  public updateSFX(id: string, data: Partial<TrackCollection>): Observable<SingleResponse<TrackCollection>> {
    return this.http.put<SingleResponse<TrackCollection>>(this.endpoint  + '/' + id + '/sfx', data);
  }

  public getRelatedTracks(id: string): Observable<PaginatedResponse<any>> {
    return this.http.get<PaginatedResponse<any>>(this.endpoint + '/' + id + '/tracks');
  }

  public updateStatus(id: string, data: Partial<TrackCollection>): Observable<SingleResponse<TrackCollection>> {
    return this.http.put<SingleResponse<TrackCollection>>(this.endpoint + '/' + id + '/status', data);
  }

  public updateCuratedByStaff(id: string, data: Partial<TrackCollection>): Observable<SingleResponse<TrackCollection>> {
    return this.http.put<SingleResponse<TrackCollection>>(this.endpoint + '/' + id + '/curated-by-staff', data);
  }

  public markAsCurated(id: string, trackIds: string[]): Observable<any> {
    return this.http.post<any>(this.endpoint + '/' + id + '/curate', {tracks: trackIds});
  }

  public markAsCorrupted(id: string, trackIds: string[]): Observable<any> {
    return this.http.post<any>(this.endpoint + '/' + id + '/corrupt', {tracks: trackIds});
  }
}

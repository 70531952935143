import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getmap'
})
export class GetMapPipe implements PipeTransform {
  public transform(value: Map<any, any> | undefined, args?: any): any {
    if (value) {
      return value.get(args);
    }
    return null;
  }
}

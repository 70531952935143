import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TrackCollection} from "../../entities/TrackCollection.entity";
import {Track} from "../../entities/Track.entity";

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  public $selectedTrack: BehaviorSubject<{tc: TrackCollection, t: Track} | null> =
    new BehaviorSubject<{tc: TrackCollection, t: Track} | null>(null);
  public $displayPlayer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public playTrack(trackCollection: TrackCollection, track: Track) {
    if(!this.$displayPlayer.getValue()) {
      this.$displayPlayer.next(true);
    }
    this.$selectedTrack.next({tc: trackCollection, t: track});
  }
}


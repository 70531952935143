import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from "@angular/common/http";
import { SharedModule } from "./modules/shared/shared.module";
import { ServicesModule } from "./modules/services/services.module";
import { ApiInterceptor } from "./modules/services/api/api.interceptor";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import {PlayerModule} from "./modules/player/player.module";
import {UrlSerializer} from "@angular/router";
import {CustomUrlSerializer} from "./utils/CustomUrlSerializer";

declare global {
  type UUID = string;
}

const loggerConfig = {
  level: NgxLoggerLevel.DEBUG,
  serverLogLevel: undefined,
  serverLoggingUrl: undefined,
};

function errorHandlerFactory() {
  return new ErrorHandler();
}

@NgModule({
  declarations: [AppComponent],
    imports: [
      FormsModule,
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      HttpClientModule,
      SharedModule,
      ServicesModule,
      LoggerModule.forRoot(loggerConfig),
      HttpClientXsrfModule.withOptions({
          cookieName: 'XSRF-TOKEN',
          headerName: 'X-XSRF-TOKEN',
      }),
      PlayerModule,
    ],
  providers: [
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      deps: [Injector],
      multi: true,
    },
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializer
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetMapPipe } from './get-map.pipe';
import { ArrayFindByUuidPipe } from './array-find-by-uuid.pipe';
import { IsArrayPipe } from './is-array.pipe';
import { TruncateStringPipe } from './truncate-string.pipe';
import {GetCombinedTrackFileStatePipe} from "./get-combined-track-file-state.pipe";
import {GetPlaylistLink} from "./get-playlist-link.pipe";

const pipes = [
  GetMapPipe,
  ArrayFindByUuidPipe,
  IsArrayPipe,
  TruncateStringPipe,
  GetCombinedTrackFileStatePipe,
  GetPlaylistLink
];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
  providers: [...pipes]
})
export class SharedPipesModule {}

import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent  {
  title = 'dayfox-admin';

  public static get mainFrontendUrl(): string {
    if (environment.env_name === 'production') {
      return 'https://dayfox-stage.teraone-preview.de';
    } else if (environment.env_name === 'stage') {
      return 'https://dayfox-stage.teraone-preview.de';
    } else if (environment.env_name === 'local') {
      return 'https://www.dayfox-backend.test:4200';
    }
    return 'https://dayfox-stage.teraone-preview.de';
  }

  constructor() {}
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../api.service';
import {PaginatedResponse, SingleResponse} from "../../../../../entities/BaseEntity.entity";
import {Category} from "../../../../../entities/Category.entity";
import {CATEGORY_MEDIA_TYPE} from "../../../../../enums/CATEGORY_MEDIA_TYPE";

@Injectable({
  providedIn: 'root'
})
export class AdminCategoryService extends ApiService {
  private endpoint = '/admin/categories';

  public getAll(page: number = 1): Observable<PaginatedResponse<Category>> {
    return this.http.get<PaginatedResponse<Category>>(this.endpoint + '?page=' + page);
  }

  public create(data: Partial<Category>): Observable<SingleResponse<Category>> {
    return this.http.post<SingleResponse<Category>>(this.endpoint, data);
  }

  public get(id: string): Observable<SingleResponse<Category>> {
    return this.http.get<SingleResponse<Category>>(this.endpoint + '/' + id);
  }

  public update(id: string, data: Partial<Category>): Observable<SingleResponse<Category>> {
    return this.http.put<SingleResponse<Category>>(this.endpoint  + '/' + id, data);
  }

  public uploadImage(id: string, type: CATEGORY_MEDIA_TYPE, file: FormData): Observable<SingleResponse<Category>> {
    file.set('type', type);
    return this.http.post<SingleResponse<Category>>(this.endpoint + '/' + id + '/upload/cover', file);
  }

  public searchTags(search: string): Observable<PaginatedResponse<Category>> {
    return this.http.get<PaginatedResponse<Category>>('/admin/search/categories/tags', {params: {search: search}});
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime, filter, startWith, switchMap, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {Playlist} from "../../../../entities/Playlist.entity";
import {PLAYLIST_TYPES} from "../../../../enums/Playlist/PLAYLIST_TYPES";

@Component({
  selector: 'app-playlist-selector',
  templateUrl: './playlist-selector.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PlaylistSelectorComponent,
    },
  ],
})
export class PlaylistSelectorComponent implements ControlValueAccessor, OnInit {
  @Input('formControl') public originalFormControl!: UntypedFormControl;
  @Input('label') public inputLabel: string = 'Playlist';
  @Input('typeFilter') public typeFilter: string[] = [];

  public autocompleteFormControl = new UntypedFormControl('', { updateOn: 'change' });
  public autocompleteSearchKeyword = 'name';
  public autocompleteSearchData: any[] = [];

  public playlist: Playlist | null = null;

  public options: Playlist[] = [];
  public optionsVisible: boolean = false;

  public playlistTypes = PLAYLIST_TYPES;

  public displayFn(playlist: Playlist): string {
    let type = 'Playlist';
    switch (playlist?.type) {
      case PLAYLIST_TYPES.ALBUM:
        type = 'Album';
        break;
      case PLAYLIST_TYPES.CURATED_PLAYLIST:
        type = 'Curated';
        break;
      case PLAYLIST_TYPES.COMPILATION:
        type = 'Compilation';
        break;
      case PLAYLIST_TYPES.SFX_PACK:
        type = 'SFXPack';
        break;
    }
    return playlist && playlist.name ? playlist.name + ' (' + type + ')' : '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onChange = (id: string | null): void => {
    //empty
  };

  public onTouched = (): void => {
    //empty
  };

  public onSelected(event: any) {
    this.playlist = event;
    this.originalFormControl.setValue(this.playlist?.id ?? null);
    this.cdr.markForCheck();
  }

  public clear(): void {
    this.markAsTouched();
    if (!this.autocompleteFormControl.disabled) {
      this.playlist = null;
      this.originalFormControl.setValue(null);
      this.autocompleteFormControl.setValue(null);
      this.onChange(null);
    }
    this.cdr.markForCheck();
  }

  public writeValue(playlistId: string | null): void {
    if (playlistId) {
      this.http.get('/admin/playlists/' + playlistId).pipe(take(1)).subscribe(
        data => {
          // @ts-ignore
          this.playlist = data.data as Playlist;
          this.cdr.markForCheck();
        }
      );
    } else {
      this.playlist = null;
    }
    this.cdr.markForCheck();
  }

  public registerOnChange(onChange: () => unknown): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => unknown): void {
    this.onTouched = onTouched;
  }

  public markAsTouched(): void {
    if (!this.autocompleteFormControl.touched) {
      this.onTouched();
      this.autocompleteFormControl.markAsTouched();
    }
  }

  public setDisabledState(disabled: boolean): void {
    disabled ? this.autocompleteFormControl.disable() : this.autocompleteFormControl.enable();
  }

  public ngOnInit() {
    this.autocompleteFormControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        filter(v => {
          const isString = typeof v === 'string';
          if (!isString && v && v.id) {
            this.markAsTouched();
            // if (!this.autocompleteFormControl.disabled) {
            //   this.playlist = v.data;
            //   this.onChange(v.data.id);
            // }
          }
          return isString;
        }),
        switchMap((search: string) => {
          let searchString = '?q=' + search;
          this.typeFilter.forEach((type) => {
            searchString = searchString + '&types[]=' + type;
          });
          return this.http.get<{ data: Playlist[] }>('/admin/search/playlists' + searchString);
        }),
      )
      .subscribe(p => {
        this.autocompleteSearchData = p.data;
        this.cdr.markForCheck();
      });
  }

  public constructor(private http: HttpClient, private log: NGXLogger, private cdr: ChangeDetectorRef) {}
}

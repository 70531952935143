import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsLoggedInGuard } from './modules/shared/guards/is-logged-in-guard.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [IsLoggedInGuard],
    loadChildren: () => import('./modules/auth-area/auth-area.module').then(m => m.LoggedInModule),
  },
  { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  { path: 'not-found', loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule) },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    paramsInheritanceStrategy: 'always',
    canceledNavigationResolution: 'replace',
    urlUpdateStrategy: 'deferred'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

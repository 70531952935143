import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import {SingleResponse} from "../../../../entities/BaseEntity.entity";
import {User} from "../../../../entities/User.entity";


@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends ApiService {
  private readonly endpoint: string = '/auth'

  public login(email: string, password: string): Observable<SingleResponse<User>> {
    return this.http.post<SingleResponse<User>>(this.endpoint + '/login', {
      email,
      password,
    });
  }

  public logout(): Observable<void> {
    return this.http.post<void>(this.endpoint + '/logout', {})
  }

  public check(): Observable<SingleResponse<User>> {
    return this.http.get<SingleResponse<User>>(this.endpoint + '/user')
  }

  public requestPasswordReset(data: any): Observable<void> {
    return this.http.post<void>(this.endpoint + '/password-reset', data);
  }

  public updatePasswordReset(searchParamsString: string, data: any): Observable<SingleResponse<User>> {
    return this.http.post<SingleResponse<User>>(this.endpoint + '/password-update?' + searchParamsString, data);
  }

  public changePassword(data: any): Observable<SingleResponse<User>> {
    return this.http.post<SingleResponse<User>>(this.endpoint + '/password-change', data);
  }

  public getImage(): Observable<{image_url: string | null, converted_image_url: string | null}> {
    return this.http.get<{image_url: string | null, converted_image_url: string | null}>(this.endpoint + '/user/image');
  }
}

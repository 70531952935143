<div class="row">
  <div class="col-12">
    <div class="w-100">
      <label>{{ inputLabel }}</label>
      <div class="position-relative">
        <ng-autocomplete #ngAutoCompleteStatic
                         [data]="autocompleteSearchData"
                         [initialValue]=""
                         [searchKeyword]="autocompleteSearchKeyword"
                         notFoundText="Not found"
                         [itemTemplate]="itemTemplateStatic"
                         [notFoundTemplate]="notFoundTemplate"
                         (selected)="onSelected($event)"
                         [formControl]="autocompleteFormControl"
                         [class.input-error]="!!originalFormControl.getError('serverError')"
                         (opened)="optionsVisible = true"
                         (closed)="optionsVisible = false"
        ></ng-autocomplete>

        <ng-template #itemTemplateStatic let-item>
          <a [innerHTML]="displayFn(item)"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <button *ngIf="user" [class.in-focus]="optionsVisible" (click)="clear()" class="btn btn-primary btn-sm position-absolute user-button">
          {{ user.name }} {{ '(' + user.email + ')' }}
        </button>
        <ng-container *ngIf="!!originalFormControl.getError('serverError')">
          <div class="text-danger">{{ originalFormControl.getError('serverError') }}</div>
        </ng-container>
      </div>
      <input class="d-none" type="text" name="" [formControl]="originalFormControl"/>

    </div>
  </div>
</div>

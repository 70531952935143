import {Pipe, PipeTransform} from '@angular/core';
import {getPlaylistLink} from "../utils/GetPlaylistLink";
import {Playlist} from "../../../entities/Playlist.entity";

@Pipe({
  name: 'getPlaylistLink',
})
export class GetPlaylistLink implements PipeTransform {
  transform(playlist: Playlist, idLink: boolean = false): string {
    return getPlaylistLink(playlist, idLink);
  }
}

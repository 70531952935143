<label>Tags</label>
<app-custom-tags-input
  class="form-control input-lg"
  [config]="customTagInputConfig"
  [tags]="trackCollectionTags"
  (onTagsChanged)="onTagsChanged($event)"
  (addTagTriggered)="addTagTrigger($event)"
  [typeaheadConfig]="customTagInputTypeaheadConfig"
  [typeaheadOptions]="tagOptions"
  (typeaheadOnNoOptionsMatch)="onNoOptionsMatch($event)"
  (inputFieldChanged)="onInputChanged($event);"
></app-custom-tags-input>

<ng-template #confirmCreateTagModal>
  <div class="modal-header">
    <h5 class="modal-title pull-left">Add Tag</h5>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="confirmCreateTagModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Do you want to add following <span class="fw-bold">NEW</span> Tag?</p>
    <p *ngIf="tempAddTag">
      Title: <span class="fw-bold">{{ tempAddTag.title }}</span><br>
      Key: <span class="fw-bold">{{ tempAddTag.key }}</span>
    </p>
  </div>
  <div class="modal-footer">
    <div class="me-auto">
      <button type="button" class="btn btn-danger" (click)="confirmCreateTagModalRef?.hide()">No</button>
    </div>
    <div class="ms-auto">
      <button type="button" class="btn btn-success" (click)="createTag()">Yes</button>
    </div>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';
import { ApiService } from "../../api.service";
import { UrlResponse } from "../../../../../entities/BaseEntity.entity";


@Injectable({
  providedIn: 'root'
})
export class AdminTrackFileUrlService extends ApiService {
  private endpoint = `/admin/tracks`;

  public wav(trackId: string): Observable<UrlResponse> {
    return this.http.get<UrlResponse>(this.endpoint + `/${trackId}/files/stream/wav`).pipe(take(1));
  }

  public mp3(trackId: string): Observable<UrlResponse> {
    return this.http.get<UrlResponse>(this.endpoint + `/${trackId}/files/stream/mp3`).pipe(take(1));
  }

  public waveform(trackId: string): Observable<UrlResponse> {
    return this.http.get<UrlResponse>(this.endpoint + `/${trackId}/files/stream/waveform`).pipe(take(1));
  }

  public watermarked(trackId: string): Observable<UrlResponse> {
    return this.http.get<UrlResponse>(this.endpoint + `/${trackId}/files/stream/watermarked`).pipe(take(1));
  }
}

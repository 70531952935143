import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../api.service';
import { PaginatedResponse } from "../../../../../entities/BaseEntity.entity";
import { Category } from "../../../../../entities/Category.entity";

@Injectable({
  providedIn: 'root'
})
export class TagService extends ApiService {
  private endpoint = '/categories/type/tags';

  public getAll(search?: string): Observable<PaginatedResponse<Category>> {
    let params = {};
    if(search) params = { q: search };
    return this.http.get<PaginatedResponse<Category>>(this.endpoint, {params: params});
  }

  public get(id: string): Observable<Category> {
    return this.http.get<Category>(this.endpoint + id);
  }
}

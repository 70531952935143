<div *ngIf="dragNDropEnabled; else baseTemplate" class="track-collection-item" cdkDrag>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</div>

<ng-template #baseTemplate>
  <div class="track-collection-item">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </div>
</ng-template>

<ng-template #content>
  <div class="play-col">
    <button (click)="playTrack()"  class="play-button btn" type="button">
      <i class="fa fa-play"></i>
    </button>
  </div>

  <div class="title-col">
    <span class="title">
      <a [routerLink]="(track.type !== trackType.SFX ? '/tracks/' : '/sfx/') + track.track_collection_id" target="_blank" class="h6 link-dark" >{{ track.title }}</a>
    </span>
  </div>

  <div class="artist-col">
    <span *ngIf="track.track_collection && track.track_collection.artist_id && track.track_collection.artist" class="artist">
        <a [routerLink]="'/artists/' + track.track_collection.artist_id" target="_blank" class="h6 fw-normal text-muted">{{ track.track_collection?.artist?.name }}</a>
    </span>
  </div>

  <div class="time-col">
    <span>
      {{ track.duration * 1000 | date: 'm:ss' }}
    </span>
  </div>

  <div class="genre-col">
    <span *ngIf="track.track_collection && track.track_collection.genres &&  track.track_collection.genres.length > 0">
      {{ track.track_collection.genres[0].title }}
    </span>
  </div>

  <div class="emotion-col">
    <span *ngIf="track.track_collection && track.track_collection.emotions &&  track.track_collection.emotions.length > 0">
      {{ track.track_collection.emotions[0].title }}
    </span>
  </div>

  <div class="video-col">
    <span *ngIf="track.track_collection && track.track_collection.video_topics &&  track.track_collection.video_topics.length > 0">
      {{ track.track_collection.video_topics[0].title }}
    </span>
  </div>

  <div class="lyrics-col">
    <i *ngIf="track.track_collection && track.track_collection.lyrics_url" class="fa-solid fa-microphone"></i>
  </div>

  <div class="tempo-col">
    <div *ngIf="track.track_collection" class="tempo">
      <span class="align-middle" [class.snail-icon]="track.track_collection.tempo == tempi.SLOW" [class.rabbit-icon]="track.track_collection.tempo == tempi.MEDIUM" [class.cat-icon]="track.track_collection.tempo == tempi.FAST"></span>
    </div>
  </div>

  <div class="published-col">
    <span *ngIf="track.track_collection && track.track_collection.published_at">
      {{ track.track_collection.published_at | date:'yyyy-MM-dd' }}
    </span>
  </div>

  <div class="utility-col">
    <button *ngIf="deleteEnabled" (click)="triggerDelete()" type="button" class="btn-clear p-1"><i class="fa-solid fa-xmark text-danger"></i></button>
    <button *ngIf="dragNDropEnabled" type="button" class="btn-clear ms-3 p-1"><i class="fa-solid fa-bars"></i></button>
  </div>
</ng-template>

<div class="track-spacer"></div>

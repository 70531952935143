import {Playlist} from "../../../entities/Playlist.entity";
import {PLAYLIST_TYPES} from "../../../enums/Playlist/PLAYLIST_TYPES";


export function getPlaylistLink(playlist: Playlist, idLink: boolean = false): string {
  if(idLink) return '/playlists/' + playlist.id;
  switch(playlist.type) {
    case PLAYLIST_TYPES.ALBUM:
      return '/playlists/album/' + playlist.slug;
    case PLAYLIST_TYPES.COMPILATION:
      return '/playlists/compilation/' + playlist.slug;
    case PLAYLIST_TYPES.SFX_PACK:
      return '/sfx/packs/' + playlist.id;
    case PLAYLIST_TYPES.CURATED_PLAYLIST:
      return '/playlists/curated/' + playlist.slug;
    default:
      return '/playlists/compilation/' + playlist.slug;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {NGXLogger} from "ngx-logger";
import {ToastrService} from "ngx-toastr";
import {TagService} from "../../../services/api/methods/category/tag.service";
import {AdminCategoryService} from "../../../services/api/methods/admin/admin-category.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {Observable, of} from "rxjs";

export type Tag<T> = { displayValue: string, model?: T, id?: string };

@Component({
  selector: 'app-custom-tags-input',
  templateUrl: './custom-tag-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomTagInputComponent<T> implements OnInit {
  @Input() public config: {
    directTagAdding: boolean,
    withTypeahead: boolean
    tagsDeletable: boolean,
    tagsAddable: boolean,
    removeLastTagOnBackspace: boolean,
    inputPlaceholder?: string,
    maxTags?: number,
  } = {
    directTagAdding: true,
    withTypeahead: false,
    tagsDeletable: true,
    tagsAddable: true,
    removeLastTagOnBackspace: true,
  };
  @Input() public tags: Tag<T>[] = [];
  @Input() public typeaheadConfig: {
    scrollableOptions: boolean,
    minLengthBeforeOptions: number
    scrollableOptionsInView: number
  } = {
    scrollableOptions: false,
    minLengthBeforeOptions: 1,
    scrollableOptionsInView: 5
  };
  @Input() public typeaheadOptions: Observable<Tag<T>[]> = of();

  @Output() public typeaheadOnNoOptionsMatch: EventEmitter<any> =  new EventEmitter<any>();
  @Output() public onTagsChanged: EventEmitter<Tag<T>[]> = new EventEmitter<Tag<T>[]>();
  @Output() public onMaxTagsReached: EventEmitter<void> = new EventEmitter<void>();
  @Output() public inputFieldChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() public addTagTriggered: EventEmitter<string> = new EventEmitter<string>();

  public selectedTypeaheadOption?: Tag<T>;
  public maxTagsReached: boolean = false;

  public ngOnInit() {}

  public addTag(input: HTMLInputElement): void {
    const inputValue = input.value.trim();
    if(inputValue !== '') {
      let tag: Tag<T> = {
        displayValue: inputValue
      };
      this.addPredefinedTag(tag);
    }
    input.value = '';
    this.cdr.markForCheck();
  }

  public addTagExternal(input: HTMLInputElement): void {
    const inputValue = input.value.trim();
    if(inputValue !== '') {
      this.addTagTriggered.emit(inputValue);
      input.value = '';
      this.cdr.markForCheck();
    }
  }

  public removeTag(index: number): void {
    if(!this.config.tagsDeletable || index >= this.tags.length) return;
    this.tags.splice(index, 1);
    this.tagsChanged();
    this.cdr.markForCheck();
  }

  private calculateMaxTagsReached(): void {
    this.maxTagsReached = this.config.maxTags !== undefined && this.tags && this.tags.length >= this.config.maxTags;
  }

  private addPredefinedTag(tag: Tag<T>) {
    this.calculateMaxTagsReached();
    const foundIndex = this.tags.findIndex((listTag) => {
      if(listTag.id && tag.id) return listTag.id === tag.id;
      return listTag === tag;
    });
    if (!this.maxTagsReached && foundIndex < 0) {
      this.tags.push(tag);
      this.tagsChanged();
    }
  }

  private tagsChanged() {
    this.onTagsChanged.emit(this.tags);
    this.calculateMaxTagsReached();
    if (this.maxTagsReached) this.onMaxTagsReached.emit();
    this.cdr.markForCheck();
  }

  public removeLastTag(input: HTMLInputElement) {
    if (!this.config.removeLastTagOnBackspace || !this.tags.length) return;
    if (input.value === '') this.removeTag(this.tags.length - 1);
  }

  public typeaheadOnNoMatch(event: any) {
    this.typeaheadOnNoOptionsMatch.emit(event);
  }

  public typeaheadOnSelect(event: any) {
    if (typeof event.item === 'string') {
      this.addPredefinedTag({
        displayValue: event.value
      });
    }
    else {
      this.addPredefinedTag(event.item);
    }
    this.selectedTypeaheadOption = undefined;
    this.cdr.markForCheck();
  }

  public onInputFieldChange(event: any) {
    this.inputFieldChanged.emit(event.currentTarget.value);
  }

  public constructor(
    private cdr: ChangeDetectorRef,
    private log: NGXLogger,
    private toastr: ToastrService,
    private tagService: TagService,
    private adminCategoryService: AdminCategoryService,
    private modalService: BsModalService
  ) {
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
}

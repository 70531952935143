import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {Subscription} from 'rxjs';
import {ImageUploadData} from 'src/app/entities/ImageUploadData.entity';

@Component({
  selector: 'app-form-video-upload',
  templateUrl: './form-video-upload.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FormVideoUploadComponent {
  @Input() public label!: string;
  @Input() public control!: UntypedFormControl;
  @Input() public name!: string;
  @Input() public required = false;
  @Output('fileChanged') public fileChanged: EventEmitter<ImageUploadData> = new EventEmitter<ImageUploadData>();

  private fileReader?: FileReader;
  private img?: HTMLImageElement;
  private formData?: FormData;
  private ratioCheckEnabled: boolean = false;

  public ngOnInit() {
    let dataUrl = null;
    this.fileReader = new FileReader();
    this.fileReader.onload = () => {
      this.fileChanged.next({
        'formData': this.formData!,
        'dataUrl': null,
      });
    };
  }

  public ngOnDestroy() {
    if(this.fileReader) {
      this.fileReader.abort();
      this.fileReader = undefined;
    }
    if(this.img) {
      this.img = undefined;
    }
  }

  public fileUploadChanged(event: any) {
    if(event.target.files && event.target.files[0] && event.target.files[0].type) {
      if(
        event.target.files[0].type.includes('video/mp4')
      ) {
        const file: File = event.target.files[0];
        const formData: FormData = new FormData();
        formData.append('image', file);
        this.formData = formData;
        this.fileReader!.readAsDataURL(event.target.files[0]);
      } else {
        this.control.setErrors(
          {
            serverError: 'Invalid file type selected, only MP4 is allowed.',
          },
          { emitEvent: true },
        );
        this.cdr.markForCheck();
      }
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {debounceTime, filter, startWith, switchMap, take} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { User } from "../../../../entities/User.entity";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UserSelectorComponent,
    },
  ],
})
export class UserSelectorComponent implements ControlValueAccessor, OnInit {
  @Input('formControl') public originalFormControl!: UntypedFormControl;
  @Input('label') public inputLabel: string = 'User / Owner';

  public autocompleteFormControl = new UntypedFormControl('', { updateOn: 'change' });
  public autocompleteSearchKeyword = 'name';
  public autocompleteSearchData: any[] = [];

  public user: User | null = null;

  public options: User[] = [];
  public optionsVisible: boolean = false;

  public displayFn(user: User): string {
    return user ? user.name + '(' + user.email + ')' : '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onChange = (id: string | null): void => {
    //empty
  };

  public onTouched = (): void => {
    //empty
  };

  public onSelected(event: any) {
    this.user = event;
    this.originalFormControl.setValue(this.user?.id ?? null);
    this.cdr.markForCheck();
  }

  public clear(): void {
    this.markAsTouched();
    if (!this.autocompleteFormControl.disabled) {
      this.user = null;
      this.originalFormControl.setValue(null);
      this.autocompleteFormControl.setValue(null);
      this.onChange(null);
    }
    this.cdr.markForCheck();
  }

  public writeValue(userId: string | null): void {
    if (userId) {
      this.http.get('/admin/users/' + userId).pipe(take(1)).subscribe(
        data => {
          // @ts-ignore
          this.user = data.data as User;
        }
      );
    } else {
      this.user = null;
    }
  }

  public registerOnChange(onChange: () => unknown): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => unknown): void {
    this.onTouched = onTouched;
  }

  public markAsTouched(): void {
    if (!this.autocompleteFormControl.touched) {
      this.onTouched();
      this.autocompleteFormControl.markAsTouched();
    }
  }

  public setDisabledState(disabled: boolean): void {
    disabled ? this.autocompleteFormControl.disable() : this.autocompleteFormControl.enable();
  }

  public ngOnInit() {
    this.autocompleteFormControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        filter(v => {
          const isString = typeof v === 'string';
          if (!isString && v && v.id) {
            this.markAsTouched();
            // if (!this.autocompleteFormControl.disabled) {
            //   this.artist = v.data;
            //   this.onChange(v.data.id);
            // }
          }
          return isString;
        }),
        switchMap((search: string) => {
          return this.http.get<{ data: User[] }>('/admin/search/users?q=' + search);
        }),
      )
      .subscribe(p => {
        this.autocompleteSearchData = [{id: environment.default_artist_user_id, name: 'Placeholder User', email: ''} as User,...p.data];
        this.cdr.markForCheck();
      });
  }

  public constructor(private http: HttpClient, private log: NGXLogger, private cdr: ChangeDetectorRef) {}
}

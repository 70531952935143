import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../api.service';
import {PaginatedResponse, SingleResponse} from "../../../../../entities/BaseEntity.entity";
import { Artist } from "../../../../../entities/Artist.entity";
import {TrackCollection} from "../../../../../entities/TrackCollection.entity";
import queryString from "query-string";
import {SearchBody} from "../../../../../entities/SearchBody.entitiy";

@Injectable({
  providedIn: 'root'
})
export class AdminArtistService extends ApiService {
  private endpoint = '/admin/artists';

  public getAll(page: number = 1): Observable<PaginatedResponse<Artist>> {
    return this.http.get<PaginatedResponse<Artist>>(this.endpoint + '?page=' + page);
  }

  public searchAll(page: number = 1, searchBody: SearchBody): Observable<PaginatedResponse<Artist>> {
    const searchQuery = queryString.stringify({page: page,...searchBody}, {
      arrayFormat: "bracket"
    });
    return this.http.get<PaginatedResponse<Artist>>('/admin/search/artists' + '?' + searchQuery);
  }

  public create(data: Partial<Artist>): Observable<SingleResponse<Artist>> {
    return this.http.post<SingleResponse<Artist>>(this.endpoint, data);
  }

  public get(id: string): Observable<SingleResponse<Artist>> {
    return this.http.get<SingleResponse<Artist>>(this.endpoint + '/' + id);
  }

  public update(id: string, data: Partial<Artist>): Observable<SingleResponse<Artist>> {
    return this.http.put<SingleResponse<Artist>>(this.endpoint  + '/' + id, data);
  }

  public updateStatus(id: string, data: Partial<Artist>): Observable<SingleResponse<Artist>> {
    return this.http.put<SingleResponse<Artist>>(this.endpoint + '/' + id + '/status', data);
  }

  public uploadImage(id: string, file: FormData): Observable<SingleResponse<Artist>> {
    return this.http.post<SingleResponse<Artist>>(this.endpoint + '/' + id + '/upload/cover', file);
  }
}

import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
// import { HttpCacheService } from './http-cache.service';
import { CookieService } from 'ngx-cookie-service';
import {UserSessionService} from "../user-session.service";
import {environment} from "../../../../environments/environment";

/**
 * This interceptor adds the correct API endpoint to each request.
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private readonly http: HttpClient;
  private log: NGXLogger;
  // private httpCacheService: HttpCacheService;
  private cookieService: CookieService;
  private platformId: Object | undefined;

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // do not modify requests to frontend server
    if (req.url.includes(`${window.location.protocol}//${window.location.host}`)) {
      return next.handle(req);
    }

    // do not modify requests for translation files
    if (req.url.includes('i18l') || req.url.includes('assets/i18l')) {
      return next.handle(req);
    }

    // do not modify requests for other domains
    if (req.url.startsWith('http') && !req.url.startsWith(environment.api.url)) {
      return next.handle(req);
    } else if (!req.url.startsWith('http')) {
      req = req.clone({
        url: environment.api.url + req.url,
      });
    }

    req = req.clone({
      withCredentials: true,
    });

    // fetch csrf token if cookie not present!
    if (!document.cookie.includes('XSRF-TOKEN') && !req.url.includes('/auth/csrf')) {
      const http: HttpClient = this.http;
      return http.get('/auth/csrf', { observe: 'response' }).pipe(
        switchMap(() => {
          // eslint-disable-next-line no-console
          console.log('before handle req headers', req.headers);
          return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
              if (error.error instanceof ErrorEvent) {
                // client-side error
              } else {
                if (error.status === 401) {
                  // unauthenticated...
                  this.injector.get(UserSessionService).logout()
                }
              }
              return throwError(error);
            }),
          );
        }),
      );
    }

    return next.handle(req).pipe(
      tap(() => {
        if (['POST', 'PUT', 'DELETE', 'PATCH'].includes(req.method)) {
          // this.httpCacheService.deleteCache();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !req.url.includes('logout')) {
          // unauthenticated...
          this.injector.get(UserSessionService).logout().subscribe();
        }
        if (error.status === 429) {
          console.error('Too many requests')
        }
        if (error && (error as HttpErrorResponse)?.status === 419) {
          this.log.warn('CSRF Token mismatch deleting cookie');
          this.cookieService.delete('XSRF-TOKEN', '/', environment.api.session_domain, true, 'Lax');
        }
        throw error;
      }),
    );
  }

  public constructor(public injector: Injector) {
    this.platformId = injector.get(PLATFORM_ID)
    this.http = injector.get(HttpClient);
    this.log = injector.get(NGXLogger);
    // this.httpCacheService = injector.get(HttpCacheService);
    this.cookieService = injector.get(CookieService);
  }
}

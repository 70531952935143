import {COMBINED_TRACK_FILE_STATES} from "../enums/Import/COMBINED_TRACK_FILE_STATES";
import {TRACK_FILE_STATES} from "../enums/Import/TRACK_FILE_STATES";
import {Track} from "../entities/Track.entity";

export function getCombinedTrackFileState(value: Track): COMBINED_TRACK_FILE_STATES {
  if(!value.file_states) return COMBINED_TRACK_FILE_STATES.INCOMPLETE;
  const states = [
    value.file_states.sd_state,
    value.file_states.waveform_state,
    value.file_states.watermarked_state,
    value.file_states.duration_state,
  ];
  if(states.includes(TRACK_FILE_STATES.FAILED)) return COMBINED_TRACK_FILE_STATES.ERROR;
  if(
    states.includes(TRACK_FILE_STATES.OUTDATED) ||
    states.includes(TRACK_FILE_STATES.NEW) ||
    states.includes(TRACK_FILE_STATES.ONGOING)
  ) return COMBINED_TRACK_FILE_STATES.INCOMPLETE;
  return COMBINED_TRACK_FILE_STATES.COMPLETE
}
